#commentWrapper {
  height: Calc(100% - 50px) !important;
  overflow: auto !important;
  background-color: #20222b;
  overflow-x: hidden !important;
  min-height: 450px;
}
#commentWrapper::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}
#commentWrapper::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
#commentWrapper::-webkit-scrollbar-thumb {
  background: #878787;
  border: 0px none #ffffff;
  border-radius: 10px;
}
#commentWrapper::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
#commentWrapper::-webkit-scrollbar-thumb:active {
  background: #000000;
}
#commentWrapper::-webkit-scrollbar-track {
  background: #0f1014;
  border: 0px none #ffffff;
  border-radius: 50px;
}
#commentWrapper::-webkit-scrollbar-track:hover {
  background: #807683;
}
#commentWrapper::-webkit-scrollbar-track:active {
  background: #333333;
}
#commentWrapper::-webkit-scrollbar-corner {
  background: transparent;
}
.borderLeftComment {
  border-left: 1px solid #6E51C9
}
.attach {
  height: 17px !important;
  margin-top: 2px;
  font-size: 16px !important;
  margin-left: 10px;
}
.canvas svg{
  height: 17px
}
.canvas {
  background-color: #6e51c9;
  padding: 2px 5px;
  margin-right: 5px;
  border-radius: 2px;
}
.media p{
  color: #d9d9d9 !important;
  font-size: 14px !important;
  font-family: "Roboto", sans-serif !important;
  text-align: left;
  margin: 5px 0 5px;
  margin-bottom: 10px;
}
.sectionCommentsMenu {
  padding-right: 5px !important;
  padding-top: 0px !important;
}
.commentSelected {
  border-left: 2px solid #6c51c0;
  transition: border-left 0.4;
  margin-left: 0px;
  color: #afb0b2 !important;
  background-color: #1a1b22;
}
.loadingWrapper {
  position: relative;
  height: 100%;
}
.sectionCommentsMenuIcon {
  width: 30px;
  margin: 0px 4px;
  border-radius: 5px;
  display: flex;
  height: 28px;
  background-color: #2f3545;
  flex-direction: row;
  padding-top: 2px;
  justify-content: center;
  height: 0;
  margin: 0;
  padding-top: 0;
  width: 50px
}
.noCaret {
  display: none !important;
}
.close {
  margin-top: 8px;
}
.sectionCommentsMenuIcon:hover {
  background-color: #15161c;
  transition: all 0.5s ease;
  cursor: pointer;
}
.sectionCommentsMenuIcon svg {
  color: #afb5c3;
  font-size: 17px;
}

.sectionCommentsMenuFilter {
  font-weight: 400;
  font-size: 0.875rem;
  margin-top: 4px;
  margin-right: 5px;
  font-family: "Montserrat", sans-serif;
}
.commentNotselected {
  border-left: 2px solid #20222b;
  color: #afb0b2 !important;
}
.media {
  transition: all 0.5s ease;
  flex: 1;
  padding-right: 0;
}

.media .media {
  margin-left: -60px;
  padding-left: 30px;
  background-color:#20222b;
  padding-right: 0;
}
.sortDropdownWrapper {
  padding-top: 0px !important;
}
.media:hover {
  transition: all 0.5s ease;
  background-color: #1a1b22;
  cursor: pointer;
}
.media:hover .media{
  transition: all 0.5s ease;
  background-color:#20222b;
  cursor: pointer;
}
.media:hover .media:hover{
  transition: all 0.5s ease;
  background-color:#1a1b22;
  cursor: pointer;
}
.circleCheckWrapper {
  display: visible;
}
.media:hover .media .edit, .media:hover .media .delete {
  display: none;
}
.media .media:hover .edit, .media .media:hover .delete {
  display: block;
}
.delete, .edit {
  visibility: hidden;
  opacity: 0;
  transition: opacity 700ms ease-out;
}
.media:hover .delete, .media:hover .edit {
  visibility: visible;
  opacity: 1
}

.buttonIcon {
  width: 45px;
  padding-right: 5px;
  margin-top: 0 !important;
  padding-left: 15px !important;
}
.tag {
  color: #20222b;
  width: fit-content;
  padding: 0 3px;
  margin-top: 2px;
  border-radius: 3px;
  padding: 0 8px;
  background-color: #6E51C9;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  color: white;
  font-weight: 600;
  padding: 1px 8px;
}
.replyComment {
  color: #636882 !important;
  font-size: 0.875rem;
  font-weight: 400;
  transition: all 0.5 linear;
}
.replyComment span:first-child {
  font-family: "Robot", "sans-serif";
  font-size: 0.875rem !important;
}
.replyComment:hover{
  color: #494e62;
}
.commentCounter {
  font-weight: 500 !important;
  font-family: "Montserrat", "timePosteds New Roman";
  display: flex;
  justify-content: flex-start;
  color: white;
  margin-left: -2px;
  padding: 0px 0 10px 0;
  white-space: nowrap;
}
.timeStamp {
  border-radius: 3px;
  margin-right: 5px;
  padding: 0 8px;
  background-color: #6E51C9;
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 12px;
  font-weight: 600;
  padding: 1px 8px;
}
