@import './variables';
@import './reset';

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}

.rbc-abs-full {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rbc-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-rtl {
  direction: rtl;
}

.event-element {
  background-color: transparent !important;
  background: url(../../../assets/icons/access_time-24px.svg) no-repeat center;
  height: 20px !important;
  width: 20px !important;
  border-radius: 50% !important;
  background-color: white !important; 

} 
.event-element .gantt_task_content {
  margin-top: -8px;
  margin-left: -10px;
  height: 50px;
  width: auto;
}
.event-element .gantt_task_progress {
  background-color: transparent !important;
}
.rbc-show-more {
  color: #535759;
}
.rbc-show-more:hover {
  color: #1f153c;
}
.event-element .rbc-event-content{ 
  color: black !important;
  max-width: 92px;
}
.event-phase {
  background-color: #3f51b7 !important; 
}
.event-element > div {
  position: absolute;
  left: 20px;
  top: 4px;
  color: black !important;

} 
.rbc-off-range {
  color: $out-of-range-color;
}

.rbc-off-range-bg {
  background: $out-of-range-bg-color;
}

.rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid $cell-border;

  & + & {
    border-left: 1px solid $cell-border;
  }

  .rbc-rtl & + & {
    border-left-width: 0;
    border-right: 1px solid $cell-border;
  }

  & > a {
    &, &:active, &:visited {
      color: inherit;
      text-decoration: none;
    }
  }
}

.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}

.rbc-today {
  background-color: $today-highlight-bg;
}

@import './toolbar';
@import './event';
@import './month';
@import './agenda';
@import './time-grid';