
.loader-content {
  margin: 0% auto 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  text-transform: uppercase;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 100%;
  position: absolute;
  top: 50%;
  width: 100%;
}

.loader-content h2 {
  font-weight: 500;
  color: #fff;
}

.loader-index {
  margin-top: 20px;
  height: 100px;
}

.loader-index>div {
  display: inline-block;
  width: 4px;
  height: 25px;
  margin: 2px;
  background: #3f2c78;
  border-radius: 2px;
  -webkit-animation: loader-index .9s -.8s infinite cubic-bezier(.85, .25, .37, .85);
  -o-animation: loader-index .9s -.8s infinite cubic-bezier(.85, .25, .37, .85);
  animation: loader-index .9s -.8s infinite cubic-bezier(.85, .25, .37, .85);
  -webkit-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both
}

.loader-index>div:nth-child(2),
.loader-index>div:nth-child(4) {
  -webkit-animation-delay: -.6s !important;
  -o-animation-delay: -.6s !important;
  animation-delay: -.6s !important
}

.loader-index>div:nth-child(1),
.loader-index>div:nth-child(5) {
  -webkit-animation-delay: -.4s !important;
  -o-animation-delay: -.4s !important;
  animation-delay: -.4s !important
}

.loader-index>div:nth-child(6) {
  -webkit-animation-delay: -.2s !important;
  -o-animation-delay: -.2s !important;
  animation-delay: -.2s !important
}

@-webkit-keyframes loader-index {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1)
  }

  50% {
    -webkit-transform: scaley(.4);
    transform: scaley(.4)
  }

  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1)
  }
}

@-o-keyframes loader-index {
  0% {
    -o-transform: scaley(1);
    transform: scaley(1)
  }

  50% {
    -o-transform: scaley(.4);
    transform: scaley(.4)
  }

  100% {
    -o-transform: scaley(1);
    transform: scaley(1)
  }
}

@keyframes loader-index {
  0% {
    -webkit-transform: scaley(1);
    -o-transform: scaley(1);
    transform: scaley(1)
  }

  50% {
    -webkit-transform: scaley(.4);
    -o-transform: scaley(.4);
    transform: scaley(.4)
  }

  100% {
    -webkit-transform: scaley(1);
    -o-transform: scaley(1);
    transform: scaley(1)
  }
}

@-webkit-keyframes loader-default {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@-o-keyframes loader-default {
  0% {
    -webkit-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0)
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes loader-default {
  0% {
    -webkit-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0)
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
  }
}
