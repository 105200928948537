body {
  align-items: flex-start !important;
}
.vimeoPlayer{
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    width: 100%;
    height: 100%;
    border: 0;
    justify-content: flex-start;
}
.responsivePlayerWrapper{
    width: 96%;
    margin-left: 2%;
    height: 70vh;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
}
.onlyofficeWrapper {
  overflow: auto !important;
  margin-bottom: 40px;
}
.iconDropdDown {
  font-size: 12px;
  height: 14px !important;
  width: 15px !important;
  margin-top: 5px !important;
  color: orange; 
  margin-left: -5px;
}
.circleIcon {
  font-size: 12px;
  height: 14px !important;
  width: 14px !important;
  margin-top: 1px !important;
}
.buttonOrange {
  color: orange;
}
.statusDropdown {
  margin-top: 0 !important;
  margin-left: -20px !important;
}
.buttonGreen {
  color: #63c648 !important;
}
.buttonRed {
  color: red !important;
}
.optionIcon svg {
  height: 18px;
  width: 30px;
  font-size: 12px;
  margin-top: 3px;
}
.optionText {
  font-size: 13px;
}
.menuList {
  background-color: black !important;
}
.sectionCommentsGrid {
  padding: 0 !important;
}
.headerContainer {
  max-width: 100% !important;
  width: 100% !important;
  padding: 0 !important;
  position: absolute;
}
.removePadding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.canvasWrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -10px;
}
.imgWrapper {
  overflow: auto; 
  width: 100%; 
  height: 100%;
  marginTop: 50px;
}
.imgWrapper::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.imgWrapper::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.imgWrapper::-webkit-scrollbar-thumb {
  background: #a5abba;
  border: 0px none #ffffff;
  border-radius: 3px;
}
.imgWrapper::-webkit-scrollbar-thumb:hover {
  background: #a5abba;
}
.imgWrapper::-webkit-scrollbar-thumb:active {
  background: #a5abba;
}
.imgWrapper::-webkit-scrollbar-track {
  background: #c6c9de;
  border: 100px none #ffffff;
  border-radius: 3px;
}
.imgWrapper::-webkit-scrollbar-track:hover {
  background: #babcd6;
}
.imgWrapper::-webkit-scrollbar-track:active {
  background: #babcd6;
}
.imgWrapper::-webkit-scrollbar-corner {
  background: transparent;
}
.close {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.close svg {
  height: 28px;
  width: 27px;
  font-size: 30px;
  color: #b8c1cf;
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.responsivePlayerWrapper iframe,
.responsivePlayerWrapper object,
.responsivePlayerWrapper embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
}
.buttonDropDown {
  background-color: #323641 !important;
  box-shadow: none !important;
  padding: 0px 17px !important;
  min-height: 28px !important;
  color: #b4bcc9;
  text-transform: none !important;
  font-size: 14px !important;
  line-height: 21px !important;
  font-weight: 500 !important;
}
.replyComment {
  color: #626780;
  display: flex;
  justify-content: flex-start;
  margin-left: 0px;
  margin-bottom: 5px;
}
.buttonDropDownIcon {
  color: #8561FF;
  margin-right: 10px !important;
  transition: 0.5s linear all !important;
}
.addTagDropdown:hover svg{
  color: #c2b2ff;
}
.commentBox {
   width: 83%;
   height: 115px;
   background-color: #363c4a;
   border-radius: 10px;
   display: flex;
   padding: 0px 15px;
   position: relative;
}
.customCaret {
  margin-top: 0px;
  margin-left: 9px !important;
}
.menuOptions{
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0px;
}

.custonInput {
  border-bottom: none !important;
  color: white !important;
}
.input {
  color: white !important;
  max-height: 70px !important;
  margin-top: -20px !important;
  overflow: hidden;
}
.customInputLabel {
  top: 0px !important;
}
.commentAvatar img {
  background-color: green;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-top: 23px;

}
.commentAvatar {
  height: 100%;
  display: flex;
  width: 50px;
  padding-right: 5px;
  justify-content: flex-start;
  flex-direction: column;
}
.commentAvatar span {
  font-family: 'Montserrat', sans-serif !important;
}
.avatar {
}
.buttonSend {
  border-radius: 5px !important;
  padding-top: 0px;
  background-color: #1d181f !important;
  box-shadow: none !important;
  margin-right: 8px !important;
  max-height: 30px;
  padding: 17px 15px !important;
  font-weight: 500 !important;
  margin-top: 13px !important;
  padding-top: 6px !important;

}
.buttonSend span {
  font-family: 'Montserrat', sans-serif;
  line-height: 9px;
}
.buttonSend:hover {
  background-color: #362d3a !important;
  box-shadow: none !important;
}
.commentTimestamp {
  height: 30px;
  margin-left: 50px;
  border-radius: 3px;
  padding: 5px;
  padding-top: 4px;
  padding-right: 8px;
  min-height: 30px;
  min-width: 20px;
  font-weight: 500;
  background-color: #20222b;
  color: #8861FF;
  display: flex;
  border-radius: 3px !important;
}
.commentTimestampContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 5px;
  margin-top: 5px;
}
.formControlCommentBox {
  padding-top: 0px !important;
  margin-top: 17px;
  font-size: 14px;
}
.formControlCommentBox textarea{
  padding-top: 5px !important;
  padding-right: 10px !important;
  color: #dadada;
  height: 32px;
  overflow: hidden;
  font-size: 14px !important;
}
.chevron {
  color: #7c6ac0;
  font-size: 21px !important;
  cursor: pointer;
  transition: 0.5s linear all !important;
}
.chevron:hover{
  color: #c2b2ff;
  font-size: 21px !important;
  cursor: pointer;
}
.videoTitle {
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  margin-top: 0px;
  margin-left: 5px;
  text-decoration: none;
  color: #dadada;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}
.videoTitle:hover {
  text-decoration: none;
  color: white;
}
.timestamp {
  margin-top: 3px;
  margin-left: 3px;
  font-size: 14px;
  color: #8861FF;
  font-family: montserrat, sans-serif !important;
  font-weight: normal;
}
.listSmallPadding {
  padding-left: 5px !important;
}
.addTagDropdown {
  margin-top: 13px !important;
  background-color: #20222b !important;
  padding: 6px 15px 5px 15px !important;
  font-size: 13px !important;
  line-height: 16px !important;
  font-family: montserrat, sans-serif !important;
  font-weight: normal !important;
  font-size: 14px !important;
}
.rowFlex {
  display: flex;
  flow-direction: row;
  margin-left: -5px;
}
.videoTitleContainer {
  width: 85%;
  display: flex;
  justify-content: left;
  margin-left: 8%;
  margin-top: 4px;
  max-height: 125px;
  overflow: auto;
}
.logo {
  position: absolute;
  left: 0;
  margin-left: 0;
}
.videoTitleContainer h2 {
  margin-top: 20px;
  font-weight: 400;
  line-height: 1rem;
  color: #dadada;
  font-size: 24px;
  line-height: 1.5em;
}
.moreIcon {
  color: white !important;
  position: absolute;
}

.playerContainer {
  border-right: 0 solid #c4c9d1;
  padding: 0 !important;
  max-height: 90vh;
  height: 100%;
  display:  flex;
  flex-direction: column;
}
.imageContent {
    width: auto;
    max-height: 100%;
    max-width: 90%;
    margin-top: 20px; 
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    margin-bottom: 20px;
    overflow: auto;
}
.videoEditorContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 !important;
}

.clock {
  font-size: 17px !important;
  height: 23px !important;
}
.paperclip {
  font-size: 20px !important;
  margin-top: 18px;
  margin-right: 10px;
  color: #b4bcc9;
  transition: color 1s linear;
  cursor: pointer;
}

.moreContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 26px;
  background-color: #323641;
  width: 30px;
  padding-left: 3px;
  padding-top: 1px;
  border-radius: 5px;
}
.paperclip:hover {
  color:#939aa5;
}
.labelFocused {
  margin-top: 5px;
  opacity: 0;
  transition: all 0.5;
}
.gridItemCommentBox {
  max-height: 126px;
  border-right: 0 solid #070709;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #0b0c0f;
  margin: 0px 2px !important;
}

@media (max-width: 700px) {
  .responsivePlayerWrapper {
    max-width: 100%;
  }
  .logo {
    display: none;
  }

  .commentBox {
    width: 90%;
    height: 150px;
    max-height: 150px;
  }
}
@media (min-width: 701px) and (max-width: 1000px) {
  .responsivePlayerWrapper {
    max-width: 96%;
    margin-left: 2%;
  }
}
@media (min-width: 1001px) and (max-width: 1400px) {
  .responsivePlayerWrapper {
    max-width: 96%;
    margin-left: 2%;
  }
  .commentAvatar img {
    margin-top: 20px;
  }
  .customInputLabel {
    top: 0px !important;
  }
  .gridItemCommentBox {
    height: 120px;
  }
  .commentBox {
    height: 110px;
    width: 88%;
  }
  .playerContainer {
    max-height: 70vh;
  }
}
@media (min-width: 1441px) and (max-width: 1679px) {
  .responsivePlayerWrapper {
    max-width: 96%;
    margin-left: 2%;
  }
  .commentBox {
    max-width: 96%;
    max-height: 110px;
  }
}

@media (min-width: 1680px) {
  .responsivePlayerWrapper {
    max-width: 96%;
    margin-left: 2%;
  }

  .commentBox {
    max-width: 88%;
    max-height: 120px;
    height: 120px;
  }
  .gridItemCommentBox {
    max-height: 150px !important;
  }
}
