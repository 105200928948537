@import './components/css/login.css';

.sweet-alert div span {
  animation: none !important;
}

.sweet-alert >div:first-child {
  border-color: #3f2c7880 !important;
  animation: none !important;
}

.dhtmlx-info {
  display: none !important
}
